import React, { useEffect, useRef, useState, forwardRef  } from 'react';

const OpenViduVideoComponent = forwardRef(({ rotateVideo, subscriber, isMuted }, ref) => {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

    // Define the function to update container dimensions
    const updateContainerDimensions = () => {
        if (containerRef.current) {
            setContainerDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    };

    // Adjust video container dimensions on window resize
    useEffect(() => {
        window.addEventListener('resize', updateContainerDimensions);
        updateContainerDimensions(); // Initial call

        return () => {
            window.removeEventListener('resize', updateContainerDimensions);
        };
    }, []);

    useEffect(() => {
        if (subscriber && videoRef.current) {
            subscriber.addVideoElement(videoRef.current);
            videoRef.current.addEventListener('loadedmetadata', () => {
                // Update container dimensions when video metadata is loaded
                updateContainerDimensions();
            });
        }
    }, [subscriber]);

    const isRotated = [90, -90, 270, -270].includes(rotateVideo);

    const getVideoStyle = () => {
        if (!videoRef.current) {
            return {}; 
        }
    const style = {
        transform: `rotate(${rotateVideo}deg)`,
        position: 'absolute',
        transformOrigin: 'center center',
    };

    if (!isRotated) {
        return { ...style, width: '100%', height: '100%', top: 0, left: 0 };
    }

    // Original video aspect ratio
    const videoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;

    // Adjusted dimensions based on rotation
    let adjustedWidth, adjustedHeight;
    if (isRotated) {
        // Swap dimensions for rotated video
        adjustedWidth = videoRef.current.videoHeight;
        adjustedHeight = videoRef.current.videoWidth;
    } else {
        adjustedWidth = videoRef.current.videoWidth;
        adjustedHeight = videoRef.current.videoHeight;
    }

    // Calculate new dimensions based on container size
    let newWidth, newHeight;
    const containerAspectRatio = containerDimensions.width / containerDimensions.height;
    if (videoAspectRatio > containerAspectRatio) {
        newWidth = containerDimensions.height * (adjustedWidth / adjustedHeight);
        newHeight = containerDimensions.height;
    } else {
        newWidth = containerDimensions.width;
        newHeight = containerDimensions.width / (adjustedWidth / adjustedHeight);
    }

    // Centering the video inside the container
    const offsetX = (containerDimensions.width - newWidth) / 2;
    const offsetY = (containerDimensions.height - newHeight) / 2;

    return { 
        ...style, 
        width: `${newHeight}px`, 
        height: `${newWidth}px`, 
        left: '0px',
        right: '0px',
        margin: 'auto' 
    };
};


    return (
        <div className="stream-container" ref={containerRef}>
            {subscriber ? (
                <video 
                    autoPlay={true} 
                    controls 
                    muted={isMuted} 
                    ref={videoRef} 
                    style={getVideoStyle()}
                />
            ) : (
                <h3 style={{ color: "#ffff" }}>Stream manager not found</h3>
            )}
        </div>
    );
});

export default OpenViduVideoComponent;
